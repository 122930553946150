/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { bool } from 'prop-types';

import mbpLogger from 'mbp-logger';
import useSalesforcePersonalizedContentQuery from '../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import useClickStreamCustomEventsTracking from '../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import { getFeatureFlag, getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';
import { getCurrentPageType } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';

import MobileGraphqlHeader from './MobileGraphqlHeader';

const MobileHeaderContainer = ({ isEyeBrowShow }) => {
    const ffIsSearchUiInMobileHeaderEnabled = useSelector(getFeatureFlag('is-search-ui-in-mobile-header-enabled'));
    const ffWhichCheckoutVariation = useSelector(getFeatureFlag('which-checkout-variation'));
    const brand = useSelector(getBrand);
    const presentationFamily = useSelector(getPresentationFamily);
    const deviceType = useSelector(getSSRDeviceType);
    const pageType = useSelector(getCurrentPageType);

    const {
        error, data, loading, variables,
    } = useSalesforcePersonalizedContentQuery({
        queryName: 'MobileHeaderLocationTest',
        interactionName: `${brand.domain} - PWA - Mobile Search Header In Header`,
        featureFlag: ffIsSearchUiInMobileHeaderEnabled,
    });

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: data?.contentSF,
        isFireImpression: true,
        page: { type: pageType },
    });

    if (loading || variables?.skip) return null;

    if (error) {
        mbpLogger.logError({
            function: 'MobileHeaderContainer',
            message: 'Error loading API data',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return (
            <MobileGraphqlHeader
                isEyeBrowShow={isEyeBrowShow}
                brand={brand}
                presentationFamily={presentationFamily}
                ffWhichCheckoutVariation={ffWhichCheckoutVariation}
                deviceType={deviceType}
            />
        );
    }

    const userGroup = data?.contentSF?.campaign?.campaignResponses?.[0]?.payload?.userGroup;

    return (
        <MobileGraphqlHeader
            isEyeBrowShow={isEyeBrowShow}
            brand={brand}
            presentationFamily={presentationFamily}
            ffWhichCheckoutVariation={ffWhichCheckoutVariation}
            deviceType={deviceType}
            userGroup={userGroup}
            trackClickThrough={trackClickThrough}
        />
    );
};

MobileHeaderContainer.propTypes = {
    isEyeBrowShow: bool.isRequired,
};

export default MobileHeaderContainer;
