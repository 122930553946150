/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React from 'react';
import { withRouter } from 'react-router';
import loadable from '@loadable/component';
import {
    object, shape, string, bool, array,
} from 'prop-types';
import { UIDReset, UIDConsumer } from 'react-uid';
import { Query } from '@apollo/client/react/components';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import mbpLogger from 'mbp-logger';
import determineLocale from '../../../helpers/contentstack/determineLocale';

// helpers, redux, gql
import { GRAPHQL_ENV } from '../../../gql';
import findHeader from '../../../gql/queries/findHeader';

import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getActiveABTests } from '../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { getCheckoutFeatureFlag } from '../../../../state/ducks/Checkout/ducks/App/App-Selectors';
import { getCountryCodeByCategoryPath } from '../../../../state/ducks/Checkout/helpers/countryCode';
import { country } from '../../../../state/ducks/App/App-Selectors';
import { getSiteWideBannerData } from './helper';

// desktop header ui components
const DesktopHeaderBlock = loadable(() => import(/* webpackChunkName: "DesktopHeaderBlock" */ './DesktopHeaderBlock'));
const AboveBannerEyebrow = loadable(() => import(/* webpackChunkName: "AboveBannerEyebrow" */ '../AboveBannerEyebrow/AboveBannerEyebrow'));
const HeaderBannersList = loadable(() => import(/* webpackChunkName: "SiteWideBanner" */ './SiteWideBanner/HeaderBannersList'));
const OfferBanner = loadable(() => import(/* webpackChunkName: "OfferBanner" */ '../OfferBanner/OfferBanner'));

const TARGET_DESKTOP = 'desktop';

// Each row of the site's desktop header is described by a ContentStack menu block.
// This component reads those in from ContentStack, then feeds them one-by-one
// to DesktopHeaderBlock which will render

const DesktopGraphqlHeader = (props) => {
    const {
        brand, layout, history, ffWhichCheckoutVariation,
        ffIsCovidEyebrowEnabled,  isEyeBrowShow,
        activeABTests, countryName, globalPartnerData,
    } = props;
    // const seed = useUIDSeed();
    const isEnterpriseCheckout = () => ((['enterprise', 'enterprise2'].includes(ffWhichCheckoutVariation) || activeABTests.checkout === 'universal') && history.location.pathname?.indexOf('/checkout/') >= 0);

    // The page load is related with checkout it doesnt show the normal header.
    if (!isEnterpriseCheckout && ffWhichCheckoutVariation === 'floral') {
        return null;
    } if (!isEnterpriseCheckout && ffWhichCheckoutVariation === 'food' && history.location.pathname?.indexOf('/checkout/order-confirmation') < 0) {
        return null;
    }

    const countryDetails = getCountryCodeByCategoryPath(history?.location?.pathname);
    const isCanadaCountry = countryDetails?.countryName?.toLowerCase() === 'canada' || countryName?.toLowerCase() === 'canada';
    const isEnglandCountry = countryDetails?.countryName?.toLowerCase() === 'england' || countryName?.toLowerCase() === 'england';
    const HEADER_QUERY = findHeader(brand, determineLocale(history, isCanadaCountry, isEnglandCountry), TARGET_DESKTOP, layout);
    // if (blockKey === 'countdown_banner') return <OfferBanner menu={blockObj.reference?.[0]} />;
    return (
        <Query query={HEADER_QUERY}>
            {({ loading, error, data }) => {
                if (loading) {
                    return null; // skeleton component
                }

                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: HEADER_QUERY,
                        component: 'DesktopGraphqlHeader.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: HEADER_QUERY,
                        component: 'DesktopGraphqlHeader.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }

                if (!data?.findHeader?.content?.entries?.length || !data.findHeader.content.entries[0]?.header_blocks?.length) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        message: 'No header blocks returned from API in DesktopGraphqlHeader',
                        brand,
                    });
                    return null;
                }

                let brandTabs = [];
                const { findHeader: headerData, findContent } = data || {};
                const entries = headerData.content.entries[0] || {};
                const headerBlocks = entries.header_blocks || [];
                const isMainNavV2Available = headerBlocks?.some((blockObj) => blockObj?.menu?.menu_ref?.[0]?._content_type_uid === 'main_navigation_v2');
                brandTabs = findContent?.content?.entries?.[0]?.brand_tab_links || [];
                const eyeBrow = entries.eyebrow || {};
                const hasCountdownBanner = eyeBrow.countdown_banner?.[0];

                const  { bannerDataTop, bannerDataBottom } =  getSiteWideBannerData(entries.site_wide_banner || []);

                return (
                    <>
                        {isEyeBrowShow && ffIsCovidEyebrowEnabled && eyeBrow && !history.location.pathname.includes('checkout') &&  !history.location.search.includes('utm_medium=bgs')
                        &&  <AboveBannerEyebrow location={history.location} data={eyeBrow} /> }
                        {hasCountdownBanner && <OfferBanner menu={eyeBrow.countdown_banner[0]} />}
                        {bannerDataTop?.length > 0 && <HeaderBannersList banners={bannerDataTop} isEyeBrowShow />}
                        <UIDReset>
                            <UIDConsumer>
                                {(id, uid) => headerBlocks.map(
                                    (block) => <DesktopHeaderBlock globalPartnerData={globalPartnerData} keeperData={entries?.keeper || {}} brandTabs={brandTabs} block={block} brand={brand} key={uid(block)} isMainNavV2Available={isMainNavV2Available} />,
                                )}
                            </UIDConsumer>
                        </UIDReset>
                        {bannerDataBottom?.length > 0 && <HeaderBannersList banners={bannerDataBottom} />}
                    </>
                );
            }}
        </Query>
    );
};

DesktopGraphqlHeader.propTypes = {
    brand: object.isRequired,
    history: object.isRequired,
    layout: string,
    isEyeBrowShow: bool.isRequired,
    ffWhichCheckoutVariation: string.isRequired,
    ffIsCovidEyebrowEnabled: bool.isRequired,
    activeABTests: shape({
        checkout: string,
    }),
    countryName: string.isRequired,
    globalPartnerData: array,
};
DesktopGraphqlHeader.defaultProps = {
    activeABTests: {},
    layout: '',
    globalPartnerData: [],
};

const mapStateToProps = (state) => ({
    ffWhichCheckoutVariation: getCheckoutFeatureFlag('which-checkout-variation')(state),
    ffIsCovidEyebrowEnabled: getFeatureFlag('is-covid-eyebrow-enabled')(state),
    activeABTests: getActiveABTests(state),
    countryName: country(state),
});

const enhance = compose(
    connect(mapStateToProps),
    withRouter,
);

export default enhance(DesktopGraphqlHeader);
